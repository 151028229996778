import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`23rd November 2021`}</p>


    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`Maximum active streams violated for this endpoint.`}</inlineCode>{` exception anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3858"
        }}>{`#3858`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3908"
        }}>{`#3908`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
        }}>{`type://DecodingClient`}</a>{` does not raise an exception anymore when duplicated encodings are specified. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3932"
        }}>{`#3932`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3934"
        }}>{`#3934`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The duplicated encodings are silently ignored.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`An early cancelled `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{` is now correctly decremented from the number of active responses. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3920"
        }}>{`#3920`}</a></li>
      <li parentName="ul">{`The text in the description sections is now correctly rendered in `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3917"
        }}>{`#3917`}</a></li>
      <li parentName="ul">{`The status of a protocol violation is now correctly logged by `}<a parentName="li" {...{
          "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
        }}>{`type://LoggingService`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3914"
        }}>{`#3914`}</a>
        <ul parentName="li">
          <li parentName="ul">{`A `}<a parentName="li" {...{
              "href": "type://ResponseHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseHeaders.html"
            }}>{`type://ResponseHeaders`}</a>{` is written exactly once when a protocol violation error is raised.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocServicePlugin:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServicePlugin.html"
        }}>{`type://DocServicePlugin`}</a>{` for gRPC now reads description from `}<inlineCode parentName="li">{`bin`}</inlineCode>{`, `}<inlineCode parentName="li">{`desc`}</inlineCode>{`, `}<inlineCode parentName="li">{`dsc`}</inlineCode>{`, `}<inlineCode parentName="li">{`pb`}</inlineCode>{`, and `}<inlineCode parentName="li">{`protobin`}</inlineCode>{`
file. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3912"
        }}>{`#3912`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://EurekaEndpointGroupBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/eureka/EurekaEndpointGroupBuilder.html"
        }}>{`type://EurekaEndpointGroupBuilder`}</a>{` now fails if an empty string is specified for `}<inlineCode parentName="li">{`appName`}</inlineCode>{` and
other parameters. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3903"
        }}>{`#3903`}</a></li>
      <li parentName="ul">{`OkHttp can be used with self-signed certificate in Armeria. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3902"
        }}>{`#3902`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Users can use `}<inlineCode parentName="li">{`https://127.0.0.1:`}</inlineCode>{` with the default self-signed certificate.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GraphqlService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlService.html"
        }}>{`type://GraphqlService`}</a>{` now produces a `}<inlineCode parentName="li">{`graphql+json`}</inlineCode>{` response when the `}<inlineCode parentName="li">{`Accept`}</inlineCode>{` header is `}<inlineCode parentName="li">{`*`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3898"
        }}>{`#3898`}</a></li>
      <li parentName="ul">{`You can now specify an empty path for the request path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3824"
        }}>{`#3824`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3942"
        }}>{`#3942`}</a></li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'eisig', 'heowc', 'hyangtack', 'ikhoon', 'jrhee17', 'minwoox', 'ohadgur', 'techno', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      